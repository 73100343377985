import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactSVG } from 'react-svg';
import downloadAppStore from '../assets/download-on-the-appstore.svg';
import downloadAppStoreSmall from '../assets/download-on-the-appstore-small.svg';
import cycling from '../assets/cycling.svg';

const Pricing = () => {

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 950);

  useEffect(() => {
    if (screenWidth < 950) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  const handleAppStorePress = () => {

    window.location.href = 'https://apps.apple.com/app/apple-store/id6474304071?pt=126798329&ct=Website&mt=8';
  };

  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Grid
        item
        sx={{ p: { xs: 0, md: 5 }, pt: { xs: 0, md: 0 } }}
        display={'flex'}
        direction={'column'}
        justifyContent={'center'}
        alignItems={smallScreen ? 'center' : 'flex-start'}
        xs={12}
        md={screenWidth > 1000 ? 6 : 12}
      >
        <Typography variant="h1" sx={{ color: '#f8f8f8', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
          Our Freemium Model
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#f8f8f8',
            textAlign: smallScreen ? 'center' : 'left',
            mt: 5,
            lineHeight: 1.5,
            fontWeight: 350,
            fontSize: {
              md: 25,
              xs: 18,
            },
          }}
        >
          Sabio is free to use, however for advanced features we offer a <b>$15/month</b> subscription,
          <br />
          <br />
          <b>You can Cancel Anytime.</b>
        </Typography>
        <Typography sx={{ mt: 5, color: '#f8f8f8', textAlign: { xs: 'center', md: 'left' } }}>
          Our mission is to make intelligent coaching available to everyone. Which is why we're priced
        </Typography>
        <Box sx={{ bgcolor: '#05AF6120', p: 1, borderRadius: 2, mt: 1, mb: 2 }}>
          <Typography sx={{ color: '#05AF61' }}>60% cheaper than alternatives</Typography>
        </Box>
        <Box onClick={handleAppStorePress} sx={{ mt: 5, mb: smallScreen ? 5 : 0 }}>
          <ReactSVG src={smallScreen ? downloadAppStoreSmall : downloadAppStore} />
        </Box>
      </Grid>
      {screenWidth > 1000 && (
        <Grid
          item
          sx={{ p: { xs: 0, md: 14 }, pt: { xs: 0, md: 7 }, display: { xs: 'none', md: 'flex' } }}
          display={'flex'}
          xs={12}
          md={6}
        >
          <ReactSVG src={cycling} />
        </Grid>
      )}
    </Grid>
  );
};

export default Pricing;
