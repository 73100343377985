import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import chat from '../assets/chat.png';

const LABELS = {
  'Onboarding': {
    small: "",
    large: "During the onboarding process, you'll tell Sabio your goal, time frame, limitations and preferences. Sabio will use this to create your plan.",
  },
  'Adjusting the Plan & Weekly Checkins': {
    small: "Tell Sabio if you need to adjust the plan, be it a change in intensity, exercises or anything else. You'll also have a weekly checkins to tell Sabio what is working and what isn't.",
    large: "As you start training with Sabio you'll be able to tell Sabio if you need to adjust the plan, whether it's a change in intensity, exercises or anything else. You'll also have a weekly checkin where you'll tell Sabio how you felt during your training and how you want to adjust for the next week.",
  },
  'Multiple Plans, Injury Mode & More': {
    small: "Sabio can create multiple plans, so you can switch between different goals, for example flexibility and that 5k you signed up for. If you have an injury, you can tell Sabio and recieve physio exerices to help you recover.",
    large: "Sabio can create multiple plans, allowing you to switch between different goals, for example improving flexibility and that 5k you signed up for. If you have an injury, you can also tell Sabio and (depending on the injury) recieve physio exerices to help you recover."
  },
};

const HowWorkBox = ({ index, title, label }) => {
  return (
    <Box sx={{ mb: 6, textAlign: { xs: 'center', md: 'left' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: { xs: 'center', md: 'flex-start' } }}>
        <Box
          sx={{
            bgcolor: '#A1AAD3',
            width: 25,
            height: 25,
            borderRadius: 12.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1,
          }}
        >
          <Typography sx={{ color: '#fff' }}>{index}</Typography>
        </Box>
        <Typography sx={{ fontWeight: 450, color: '#A1AAD3' }}>{title}</Typography>
      </Box>
      <Typography sx={{ fontWeight: 350, color: '#f8f8f8' }}>{label}</Typography>
    </Box>
  );
};

const HowWorks = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 700);

  React.useEffect(() => {
    if (screenWidth < 700) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <Box>
      <Typography variant="h1" sx={{ color: '#f8f8f8', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
        How Does Sabio Work?
      </Typography>
      <Typography
        sx={{
          color: '#f8f8f8',
          textAlign: 'center',
          mt: 3,
          mb: { xs: 8, md: 2 },
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        Feel the Presence of an In-Person Coach, Even Virtually.
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: 10 }, pt: { xs: 0, md: 11 } }}>
          <HowWorkBox
            index={1}
            title={'Onboarding with Sabio'}
            label={
              "During the onboarding process, you'll tell Sabio your goal, time frame, limitations and preferences. Sabio will use this to create your plan."
            }
          />
          <HowWorkBox
            index={2}
            title={'Adjusting the Plan & Weekly Checkins'}
            label={screenWidth < 1425 ? LABELS['Adjusting the Plan & Weekly Checkins'].small : LABELS['Adjusting the Plan & Weekly Checkins'].large}
          />
          <HowWorkBox
            index={3}
            title={'Multiple Plans, Injury Mode & More'}
            label={screenWidth < 1425 ? LABELS['Multiple Plans, Injury Mode & More'].small : LABELS['Multiple Plans, Injury Mode & More'].large}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          sx={{ p: { xs: 0, md: 0 }, pt: { xs: 5, md: 3 } }}
        >
          <img src={chat} height={smallScreen ? 340 : 500} alt="" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWorks;
