import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ArrowForward } from '@mui/icons-material';
import adaptable from '../assets/adaptable.png';


const SolutionStatement = () => {
  const [value, setValue] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  const handleChange = (e, v) => {
    setValue(v);
  };

  const handleAppStore = () => {
    window.location.href = 'https://apps.apple.com/app/apple-store/id6474304071?pt=126798329&ct=Website&mt=8';
  };

  return (
    <Box>
      <Typography variant="h1" sx={{ color: '#f8f8f8', textAlign: 'center', fontSize: { xs: 30, md: 40 }, mt: 2 }}>
        Maintain your fitness goals despite disruptions.
      </Typography>
      <Typography
        sx={{
          color: '#f8f8f8',
          textAlign: 'center',
          mt: 3,
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        Sabio adapts your fitness plan to overcome the twists and turns of life.
      </Typography>
      <Box sx={{ marginTop: 5, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={adaptable} height={450} alt="Adaptable" />
      </Box>
    </Box>
  );
};

export default SolutionStatement;
