import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactSVG } from 'react-svg';
import Rating from '@mui/material/Rating';
import Container from '@mui/material/Container';

import appstore from '../assets/appstore.svg';
import logoWithBackground from '../assets/logo-with-background.svg';
import profile from '../assets/profile.svg';
import { Button } from '@mui/material';
import { StarBorder } from '@mui/icons-material';

const ReviewBox = ({ name, date, text }) => {
  return (
    <Grid
      item
      xs={12}
      md={2.8}
      sx={{ bgcolor: '#1F2025', borderRadius: 5, height: { xs: 190, md: 210 }, mb: { xs: 5, md: 0 } }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ReactSVG src={profile} />
        <Box>
          <Typography sx={{ color: '#f8f8f8', fontWeight: 450, fontSize: 16, lineHeight: 1.3 }}>{name}</Typography>
          <Typography sx={{ fontWeight: 300, fontSize: 12, color: '#BFBFBF' }}>{date}</Typography>
        </Box>
      </Box>
      <Rating size="small" sx={{ m: 1 }} name="read-only" defaultValue={5} readOnly />
      <Typography sx={{ fontSize: 15, fontWeight: 350, p: 1, color: '#f8f8f8' }}>{text}</Typography>
    </Grid>
  );
};

const AppStoreReviewBoxes = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ width: { xs: '100%', md: '90%' }, margin: 'auto', mt: { xs: 5, md: 8 }, justifyContent: 'space-between' }}
    >
      <ReviewBox
        name={'ClaireReview64'}
        date={'Feb 2nd'}
        text={'I came across Sabio & just signed up, really impressed with the initial chat & the plan so far.'}
      />
      <ReviewBox
        name={'Zessi_69'}
        date={'Jan 31st'}
        text={"This app is really helping me stick with my weight loss goal, can't reccomend it enough."}
      />
      <ReviewBox
        name={'Elnaz03'}
        date={'Mar 4th'}
        text={'It gives me a training plan based on my breathing with focused runs and intervals to manage my asthma.'}
      />

      <ReviewBox
        name={'TurnP'}
        date={'Feb 2nd'}
        text={'The two week schedule it generated felt very well balanced and I’ve been sticking with it.'}
      />
    </Grid>
  );
};

const AppStoreReviewSummary = () => {
  return (
    <Container
      sx={{
        bgcolor: '#1F2025',
        height: 100,
        borderRadius: 3,
        mt: { xs: 5, md: 6 },
        p: 2.5,
        width: { xs: '100%', md: '70%' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box id="top" sx={{ display: 'flex', alignItems: 'center' }}>
          <ReactSVG src={appstore} />
          <Typography sx={{ fontWeight: 450, ml: 1, fontSize: 20, mb: '3px', color: '#f8f8f8' }}>App Store Rating</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 450, fontSize: 20, mr: 1, color: '#f8f8f8' }}>4.6</Typography>
          <Rating sx={{ mb: '2px' }} name="read-only" defaultValue={4.6} precision={0.1} readOnly emptyIcon={<StarBorder style={{ color: '#FAAF03' }} />} />
        </Box>
      </Box>
      <ReactSVG src={logoWithBackground} />
    </Container>
  );
};

const Reviews = () => {
  return (
    <Box>
      <Typography
        variant="h1"
        sx={{ color: '#f8f8f8', textAlign: 'center', fontSize: { xs: 30, md: 40 }, lineHeight: { xs: 1.5, md: 1 } }}
      >
        4,482 Workouts Completed with Sabio
      </Typography>
      <Typography
        sx={{
          color: '#f8f8f8',
          textAlign: 'center',
          mt: 3,
          mb: { xs: 8, md: 10 },
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        Increasing every day.
      </Typography>
      <AppStoreReviewSummary />
      <AppStoreReviewBoxes />
    </Box>
  );
};

export default Reviews;
