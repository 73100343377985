import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { ReactSVG } from 'react-svg';
import logo from '../assets/logo.svg';

const pages = ['Pricing', 'Reviews', 'FAQ', 'Contact'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const [float, setFloat] = React.useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    console.log(position);
    setScrollPosition(position);
  };

  React.useEffect(() => {
    const url = window.location.href;
    const page = url.split('/')[url.split('/').length - 1];
    const capitalised = page === 'faq' ? 'FAQ' : page.charAt(0).toUpperCase() + page.slice(1);
    handleNavPress(capitalised);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (scrollPosition > 120) {
      setFloat(true);
    } else {
      setFloat(false);
    }
  }, [scrollPosition]);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  const getPosition = (link) => {
    if (link === 'Pricing') {
      if (smallScreen) {
        return 3361;
      } else {
        return 2782;
      }
    }
    if (link === 'Reviews') {
      if (smallScreen) {
        return 3945;
      } else {
        return 3440;
      }
    }
    if (link === 'FAQ') {
      if (smallScreen) {
        return 5291;
      } else {
        return 4147;
      }
    }
    if (link === 'Contact') {
      if (smallScreen) {
        return 6135;
      } else {
        return 4811;
      }
    }

    if (link === 'Blog') {
      window.location.href = '/blog';
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleGetStarted = () => {
    handleCloseNavMenu();
    window.location.href = 'https://apps.apple.com/us/app/sabio/id6474304071';
  };

  const handleLogoPress = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleNavPress = (link) => {
    window.scrollTo({
      top: getPosition(link),
      behavior: 'smooth',
    });

    handleCloseNavMenu();
  };

  return (
    <AppBar
      position={'fixed'}
      sx={[
        {
          backgroundColor: '#16171B',
          boxShadow: 'none',
          px: { md: 3 },
          py: { xs: 2, md: 1 },
        },
        float && { bgcolor: 'transparent' },
      ]}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={[
            { backgroundColor: '#16171B', transition: 'all 0.3s ease' },
            float && {
              m: 'auto',
              width: screenWidth < 1401 ? '95%' : '70%',
              borderRadius: '20px',
              px: { xs: 2, md: 2 },
              py: { xs: 1, md: 0 },
              border: '1px solid #A1AAD340',
            },
          ]}
        >
          <Box onClick={handleLogoPress} style={{ cursor: 'pointer' }}>
            <ReactSVG src={logo} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleNavPress(page)}
                sx={{ my: 2, mx: screenWidth < 1054 ? 1 : 3, display: 'block', color: '#f8f8f8' }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onClick={handleGetStarted}
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                display: 'block',
                '&:hover': { bgcolor: 'primary.main', opacity: 0.8 },
              }}
            >
              Get Started
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' }, bgcolor: '#16171B' }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#f8f8f8"
            >
              <MenuIcon style={{ color: '#f8f8f8' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '100%',
                mt: 2,
              }}
              PaperProps={{
                style: float
                  ? {
                    borderRadius: 12,
                    width: '70%',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 12px',
                    backgroundColor: '#16171B',
                  }
                  : {
                    borderRadius: 12,
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 12px',
                    backgroundColor: '#16171B',
                  },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page} onClick={() => handleNavPress(page)} sx={{ bgcolor: '#16171B', '&:hover': { bgcolor: '#1F2025' } }}>
                  <Typography textAlign="center" sx={{ color: '#f8f8f8' }}>{page}</Typography>
                </MenuItem>
              ))}
              <Divider variant="middle" sx={{ opacity: 0.6 }} />
              <MenuItem onClick={handleCloseNavMenu} sx={{ '&:hover': { bgcolor: '#16171B' } }}>
                <Button
                  onClick={handleGetStarted}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    color: '#fff',
                    boxShadow: 'none',
                    '&:hover': { bgcolor: 'primary.main', opacity: 0.8, boxShadow: 'none' },
                  }}
                >
                  Get Started
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
