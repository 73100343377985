import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ED650E',
    },
    secondary: {
      main: '#272620',
    },
    background: {
      default: '#16171B',
    },
    text: {
      primary: '#f8f8f8',
      secondary: 'rgba(0, 0, 0, 0.7)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: '#000',
          padding: 12,
          borderRadius: 12,
          fontWeight: 400,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          color: '#272620',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#',
        },
        h1: {
          color: 'white',
          fontSize: 60,
          fontWeight: 400,
        },
        h2: {
          fontSize: 25,
          fontWeight: 400,
          color: 'white',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        indicator: {
          // Style for the indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        selected: {},
      },
    },
  },
});

export default theme;
