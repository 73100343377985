import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const headerStyle = { fontWeight: 450, color: '#f8f8f8' };
const answerStyle = { fontWeight: 350, color: '#f8f8f8' };

const FAQs = () => {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {

    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Container fullWidth sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
        <Box
          sx={{
            border: '1px solid #EE6E12',
            bgcolor: '#EE6E1210',
            p: 1,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid #EE6E12',
              bgcolor: '#EE6E1230',
              borderRadius: 10,
              width: 20,
              height: 20,
              mr: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: 12, color: '#EE6E12', fontWeight: 450 }}>?</Typography>
          </Box>
          <Typography sx={{ mr: 0.6, color: '#EE6E12', fontWeight: 450 }}>FAQ</Typography>
        </Box>
      </Container>
      <Typography variant="h1" sx={{ color: '#f8f8f8', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: { md: '80%', xs: '100%' }, margin: 'auto', mt: 10 }}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none', p: 1, bgcolor: '#1F2025' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel1-content" id="panel1-header">
            <Typography sx={headerStyle}>Can I have multiple goals?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>Sabio has been optimised to work effectively with one goal.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          sx={{ boxShadow: 'none', borderTop: '1px solid #1F2025', p: 1, bgcolor: '#1F2025' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel2-content" id="panel2-header">
            <Typography sx={headerStyle}>What makes Sabio different from other fitness apps?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>
              An intense focus on adaptability. Our lives often get in the way of our fitness goals, and the reality is
              regular training plans can't accommodate for this.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          sx={{ boxShadow: 'none', borderTop: '1px solid #1F2025', p: 1, bgcolor: '#1F2025' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel3-content" id="panel3-header">
            <Typography sx={headerStyle}>How does Sabio personalize my fitness plan?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>
              The onboarding conversation gathers a wealth of information about you, your lifestyle and your goals, if
              you connect your Strava we can enrich this information with your workout history. As you progress your
              plan, you can converse with Sabio on how you feel and any changes you'd like to make.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          sx={{ boxShadow: 'none', borderTop: '1px solid #1F2025', p: 1, bgcolor: '#1F2025' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel4-content" id="panel4-header">
            <Typography sx={headerStyle}>How does Sabio adapt to my progress?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>
              With a Strava connection Sabio can view your performance in real-time and make updates week by week.
              Alternatively you can message Sabio and based on your conversation adjustments to the plan are made.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          sx={{ boxShadow: 'none', borderTop: '1px solid #1F2025', p: 1, bgcolor: '#1F2025' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel5-content" id="panel5-header">
            <Typography sx={headerStyle}>Does Sabio provide nutritional advice?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>Sabio can provide nutritional advice, all you have to do is ask!</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ boxShadow: 'none', p: 1, bgcolor: '#1F2025' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#f8f8f8' }} />} aria-controls="panel6-content" id="panel6-header">
            <Typography sx={headerStyle}>How do I give feedback to Sabio about a workout or plan?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={answerStyle}>
              You can message Sabio on the chat screen to let him know how things are going.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FAQs;
