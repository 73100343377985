import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactSVG } from 'react-svg';
import downloadAppStore from '../assets/download-on-the-appstore.svg';
import downloadAppStoreSmall from '../assets/download-on-the-appstore-small.svg';
import HeroImg from '../assets/home.png';

const Hero = () => {


  const handleAppStorePress = () => {

    window.location.href = 'https://apps.apple.com/app/apple-store/id6474304071?pt=126798329&ct=Website&mt=8';
  };

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 700);

  useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Grid
        item
        sx={{ p: { xs: 0, md: 10 }, pt: { xs: 0, md: 0 } }}
        display={'flex'}
        direction={'column'}
        justifyContent={'center'}
        alignItems={smallScreen ? 'center' : 'flex-start'}
        xs={12}
        md={6}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: smallScreen ? 'center' : 'left',
            fontSize: {
              md: 60,
              xs: 30,
            },
          }}
        >
          Keep Your Fitness on Track, Every Day.
        </Typography>
        <Typography
          variant="h2"
          sx={{
            textAlign: smallScreen ? 'center' : 'left',
            mt: 5,
            fontWeight: 350,
            fontSize: {
              md: 25,
              xs: 18,
            },
          }}
        >
          Sabio creates personalised fitness plans that adapt to the twists and turns of everyday life.
        </Typography>
        <Box onClick={handleAppStorePress} sx={{ mt: 5, mb: smallScreen ? 5 : 0 }}>
          <ReactSVG src={smallScreen ? downloadAppStoreSmall : downloadAppStore} />
        </Box>
      </Grid>
      <Grid
        item
        sx={{ p: { xs: 0, md: 10 }, pt: { xs: 0, md: 10 } }}
        display={'flex'}
        justifyContent={'center'}
        xs={12}
        md={6}
      >
        <img src={HeroImg} height={smallScreen ? 300 : 500} alt="" />
      </Grid>
    </Grid>
  );
};

export default Hero;
